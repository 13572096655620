<template>
  <div class="container">
    <div class="block" style="margin-bottom:10px">
      <el-button
        type="success"
        icon="el-icon-plus"
        @click="dialogVisible = true"
        >新增</el-button
      >
      <!-- <el-button type="success" icon="el-icon-plus" @click="setDayNum">每日限额</el-button> -->
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      >
      <el-table-column
        prop="name"
        label="名称"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="nowRole == '系统管理员'"
        prop="departmentName"
        label="关联部门"
        align="center"
      >
        <template slot-scope="{ row }">
          <span @click="showAllDepartment(row.id)">
            {{ row.departmentName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="contactPerson"
        label="联系人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="contactPhone"
        label="联系电话"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        align="center"
      ></el-table-column>
      <el-table-column label="审批期" prop="reservationDay" align="center" />
      <!-- <el-table-column label="每日预约数量限额" prop="reservationQuantity" /> -->
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="{ row, $index }">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            @click="setDayNum(row.id)"
            >每日限额</el-button
          >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit"
            @click="showEditBox($index)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="handleDel(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!--医院关联部门-->

    <el-dialog
      :visible.sync="departmentListShow"
      width="600px"
      :title="'关联部门'"
    >
      <div class="departmentUl">
        <el-tag
          v-for="item in hosDepartmentList"
          :key="item.id"
          style="margin:5px"
          >{{ item.name }}</el-tag
        >
      </div>
    </el-dialog>
    <!--    添加用户模态窗-->
    <el-dialog
      :title="handleType === 'add' ? '新增医院' : '编辑医院'"
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <el-form ref="form" :model="singleArr" label-width="auto">
        <el-form-item label="名称">
          <el-input v-model="singleArr.name" />
        </el-form-item>
        <el-form-item v-if="nowRole == '系统管理员'" label="部门">
          <div class="block">
            <el-cascader
              v-model="singleArr.departmentId"
              :options="departmentListArr"
              style="width:100%"
              :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                multiple: true
              }"
              collapse-tags
            ></el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="singleArr.contactPerson" />
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="singleArr.contactPhone" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="singleArr.remark" />
        </el-form-item>
        <el-form-item label="审批期">
          <el-input v-model="singleArr.reservationDay"></el-input>
        </el-form-item>
        <!-- <el-form-item label="每日预约数量限额">
          <el-input v-model="singleArr.reservationQuantity"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="handleType === 'add'" type="primary" @click="handleAdd"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit">确 定</el-button>
      </span>
    </el-dialog>
    <div v-if="setDayShow" class="setwrap">
      <h3>每日限额设置</h3>
      <ul>
        <li v-for="(item, index) in 31" :key="index">
          <div class="day">
            Day
            <b>{{ item }}</b>
          </div>
          <div class="num">
            <em @click="setNum(index, '0')">-</em>
            <span>
              <input
                v-model="dayNumObj[index].number"
                type="text"
                style="height:30px"
              />
            </span>
            <em @click="setNum(index, '1')">+</em>
          </div>
        </li>
      </ul>
      <div class="clear"></div>
      <div class="sub">
        <el-button @click="setDayShow = false">取 消</el-button>
        <el-button type="primary" @click="updateDayNum">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/hospital';

export default {
  name: 'Hospital',

  data() {
    return {
      dataList: [],
      dayNumObj: [],
      singleObj: null,
      dialogVisible: false,
      listLoading: false,
      handleType: 'add',
      checkboxArr: [],
      setDayShow: false,
      nowRole: sessionStorage.getItem('roleName'),
      singleArr: {},
      departmentListArr: [],
      departmentListShow: false,
      hosDepartmentList: null
    };
  },
  watch: {},
  created() {
    this.handleGetList();
    this.departmentList();
  },

  methods: {
    async setDayNum(id) {
      await api.getDetailApi(id).then(response => {
        this.singleObj = response.data;
        this.dayNumObj = JSON.parse(response.data.reservationQuantity);
        this.setDayShow = true;
      });
    },
    setNum(index, type) {
      if (type == '1') {
        let newNum = this.dayNumObj[index].number;
        this.$set(this.dayNumObj, index, {
          day: index + 1,
          number: newNum + 1
        });
      } else {
        let newNum = this.dayNumObj[index].number;
        if (newNum == 0) {
          return;
        }
        this.$set(this.dayNumObj, index, {
          day: index + 1,
          number: newNum - 1
        });
      }
    },
    async updateDayNum() {
      const query = {
        id: this.singleObj.id,
        reservationQuantity: this.dayNumObj
      };
      await api.updateDataApi(query).then(response => {
        this.$message({
          type: 'success',
          message: response.message
        });
        this.setDayShow = false;
      });
    },

    //根据医院ID获取所属部门列表
    showAllDepartment(hid) {
      let _this = this;
      api.getDepartmentListByHospitalId(hid).then(res => {
        _this.hosDepartmentList = res.data;
        console.log('de', _this.hosDepartmentList);
        _this.departmentListShow = true;
      });
    },
    //获取部门列表
    departmentList() {
      api.getDepartmentList().then(response => {
        this.departmentListArr = response.data;
        this.listLoading = false;
        console.log(this.departmentListArr);
      });
    },
    //列表
    handleGetList() {
      this.listLoading = true;

      var userId = sessionStorage.getItem('userId');

      api.getAllListApi(userId).then(response => {
        this.dataList = response.data.data;
        this.listLoading = false;
      });
    },

    //新增
    handleAdd() {
      if (this.singleArr.name == '') {
        this.$message({
          type: 'error',
          message: '请填写名称'
        });
        return;
      }

      if (this.singleArr.departmentId.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择部门'
        });
        return;
      }
      var arr = [];
      for (var i = 0; i < this.singleArr.departmentId.length; i++) {
        arr.push(this.singleArr.departmentId[i][1]);
      }
      const query = {
        name: this.singleArr.name,
        departmentId: arr,
        contactPerson: this.singleArr.contactPerson,
        contactPhone: this.singleArr.contactPhone,
        remark: this.singleArr.remark
      };

      api.addDataApi(query).then(res => {
        if (res.code === 200) {
          this.handleGetList();
          this.dialogVisible = false;
          this.$message({
            type: 'success',
            message: '添加成功'
          });

          this.singleArr = {};
        }
      });
    },
    //删除

    handleDel(id) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const query = { id: id };
        api.delDataApi(query).then(res => {
          if (res.code === 200) {
            this.handleGetList();
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      });
    },
    //批量删除

    batchDel() {
      if (this.checkboxArr.length == 0) {
        this.$message({
          type: 'error',
          message: '请至少选择一项'
        });
        return;
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const query = this.checkboxArr;
        api.delDataApi(query).then(res => {
          if (res.code === 200) {
            this.handleGetList();
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      });
    },

    showEditBox(num) {
      this.handleType = 'edit';
      this.singleArr = this.dataList[num];
      this.dialogVisible = true;
    },
    //编辑
    handleEdit() {
      const arr = [];
      let query = {};
      if (this.nowRole == '系统管理员') {
        if (!this.singleArr.departmentId) {
          this.$message.error('请选择关联部门');
          return;
        }
        for (let i = 0; i < this.singleArr.departmentId.length; i++) {
          arr.push(this.singleArr.departmentId[i][1]);
        }
        query = Object.assign(this.singleArr, { departmentId: arr });
      } else {
        query = this.singleArr;
      }

      api.updateDataApi(query).then(res => {
        if (res.code === 200) {
          this.handleGetList();
          this.editBoxShow = false;
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          this.dialogVisible = false;
          this.singleArr = {};
        }
      });
    },

    showAddBOX() {
      this.singleArr = {};
      this.dialogVisible = true;
    },
    handleSelectionChange(val) {
      this.checkboxArr = [];
      val.forEach(item => {
        this.checkboxArr.push(item.id);
      });
    }
  }
};
</script>

<style scoped>
.departmentUl {
  list-style: none;
}

.setwrap {
  width: 810px;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 10px;
  z-index: 9999;
}
.setwrap h3 {
  line-height: 40px;
  font-weight: normal;
  text-align: center;
}
.setwrap .day {
  background: #d4d4d4;
  text-align: center;
  height: 40px;
  line-height: 40px;
}
.setwrap .day b {
  margin-right: 5px;
}
.setwrap .clear {
  clear: both;
}
.setwrap .sub {
  text-align: center;
}
.setwrap ul {
  list-style: none;
}
.setwrap ul li {
  width: 120px;
  float: left;
  margin: 5px;
  overflow: hidden;
  border-radius: 4px;
}
.num {
  border: 1px solid #dcdfe6;
}
.num input {
  line-height: 30px;
  width: 57px;
  text-align: center;
  border: none;
}
.num em {
  width: 30px;
  height: 25px;
  display: inline-block;
  cursor: pointer;
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  background: #f5f7fa;
}
.num span {
  display: inline-block;
}
</style>
