import request from '@/utils/request';

const Api = {

    //获取部门列表

    async getDepartmentList() {
        return await request.post('/department/getList');
    },
    async getAllListApi(str) {
        return await request.get('/medical/hospital?userId=' + str);
    },
    async getAllAppointment(params) {
        return await request.post('/hospital/appointment/getAllList', params);
    },
    
    async addDataApi(params) {
        return await request.post('/medical/hospital/create', params);
    },

    async delDataApi(params) {
        return await request.post('/medical/hospital/remove', params);
    },

    async updateDataApi(params) {
        return await request.post('/medical/hospital/update', params);
    },
    async getDetailApi(str) {
        return await request.get('/medical/hospital/detail?id=' + str);
    },
    async getDepartmentListByHospitalId(str) {
        return await request.get('/medical/hospital/findDepartmentByHospitalId?hospitalId=' + str);
    },
        //获取消费分组
        async getDropDownList() {
            return await request.get('/canteenConsumerGroup/dropDownList');
        },
    
};

export default Api;